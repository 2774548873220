import * as React from 'react';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';

import { Link } from '../Link';
import { P, Text } from '../Primitives';

type CardProps = React.ComponentProps<typeof Column> & {
  title: string;
  subtitle?: string;
  text?: string | React.ReactNode;
  url?: string;
  image?: React.ReactNode;
  textAlign?: React.CSSProperties['textAlign'];
};

export const Card = ({
  title,
  subtitle,
  text,
  image,
  url,
  textAlign,
  children,
  ...props
}: CardProps) => {
  const content = text ? (
    <P size="s" textAlign={textAlign}>
      {text}
    </P>
  ) : (
    children
  );

  return (
    <CardWrapper url={url}>
      <Column space={theme.space.xxxs} {...props}>
        {image}

        <Column space={0}>
          <Text as="h3" textAlign={textAlign} bold>
            {title}
          </Text>
          {subtitle && (
            <Text as="h4" size="s" textAlign={textAlign} semiBold>
              {subtitle}
            </Text>
          )}
        </Column>

        {content}
      </Column>
    </CardWrapper>
  );
};

const CardWrapper = ({ url, children }: React.PropsWithChildren<Pick<CardProps, 'url'>>) =>
  url ? (
    <Link openInNewTab variant="unstyled" url={url}>
      {children}
    </Link>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
